.exam-form {
	position: relative;
	padding-left: 20px;
}

.form-button {
	position: absolute;
	transform: rotate(-90deg);
}

.back-button {
	position: absolute;
	left: -30px;
	top: 120px;
	transform: rotate(-90deg);
}

.form-grid {
	flex-direction: row;
}

@media (max-width: 981px) {
	.form-grid {
		flex-direction: column;
	}
}

@keyframes formanimation {
	from {
		transform: translateX(402px)
	}
	to {
		transform: translateX(0)
	}
}